<template>
  <div class="mb-sm-4 mx-3 my-4 row">
    <div class="col-lg-12">
      <div class="row p-4">
        <div class="col">
          <h5 class="h5 text-muted font-weight-bold">{{ title }}</h5>
          <p>{{ description }}</p>
        </div>
      </div>
      <div class="row p-4">
        <div class="col">
          <div class="progress" style="height: 2.2rem; border-radius: 30px">
            <div
              class="progress-bar bg-success progress-bar-striped progress-bar-animated"
              role="progressbar"
              :style="styleObject"
              :aria-valuenow="progress"
              aria-valuemin="0"
              aria-valuemax="100"
            >
              {{ progress }}%
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LoadingComponent",
  props: {
    progress: {
      type: Number,
      default: 0,
    },
    title: {
      type: String,
      default: "Applying Consistency Checks",
    },
    description: {
      type: String,
      default: "Please wait while the uploaded data is checked and cleaned for consistency",
    },
  },
  computed: {
    styleObject() {
      return {
        width: `${this.progress}%`,
        "border-radius": "30px",
      };
    },
  },
};
</script>
